var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasProducts)?[_c('v-data-table',{attrs:{"dense":"","hide-default-footer":"","disable-sort":"","headers":_vm.headers,"items":_vm.purchaseProducts,"item-class":_vm.checkIfNewProduct,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[(item.product != null && item.product.id != null)?[_vm._v(" "+_vm._s(item.product.code)+" - "+_vm._s(item.product.name)+" ")]:[_vm._v(" "+_vm._s(item.product_name)+" ")]],2)]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.quantity_multiple * item.raw_quantity))+" ")]}},{key:"item.purchase_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.price))+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.discount))+" ")]}},{key:"item.shipping",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.shipping))+" ")]}},{key:"item.product_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_total))+" ")]}},{key:"item.purchase_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.purchase_total))+" ")]}},{key:"item.action",fn:function(ref){
var index = ref.index;
return [(_vm.isDraft)?_c('div',[_c('v-btn',{attrs:{"text":"","small":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('editProduct', index)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"text":"","small":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('deleteProduct', index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()]}}],null,true)})]:_vm._e(),(_vm.isDraft)?[_c('v-btn',{staticClass:"my-5",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('addProduct')}}},[_c('v-icon',[_vm._v("mdi-plus ")]),_vm._v(" Adicionar Produto ")],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }