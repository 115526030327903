<template>
  <v-card color="grey lighten-3 " elevation="0">
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div>
          <h4>Total dos produtos</h4>
        </div>
        <div class="text-right">
          <h4>{{ $format.decimal(product_total) }}</h4>
        </div>
      </div>

      <div class="d-flex justify-space-between mt-3" v-if="shipping_total > 0">
        <div>
          <span>Frete</span>
        </div>
        <div class="text-right">
          <span>{{ $format.decimal(shipping_total) }}</span>
        </div>
      </div>

      <div class="d-flex justify-space-between mt-3">
        <div>
          <h3>Total do Pedido</h3>
        </div>
        <div class="text-right">
          <h3>{{ $format.decimal(purchase_total) }}</h3>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {},
  },

  data() {
    return {
      purchase: {},
    };
  },

  computed: {
    // discount_total() {
    //   return this.$calc.sum(this.purchase.purchase_order_products, "discount");
    // },

    // icms_calc_base() {
    //   return this.$calc.sum(this.purchase.purchase_order_products, "icms_calc_base");
    // },

    // icms_total() {
    //   return this.$calc.sum(this.purchase.purchase_order_products, "icms");
    // },

    // icms_st_calc_base() {
    //   return this.$calc.sum(
    //     this.purchase.purchase_order_products,
    //     "icms_st_calc_base"
    //   );
    // },

    // icms_st_total() {
    //   return this.$calc.sum(this.purchase.purchase_order_products, "icms_st");
    // },

    // ipi_calc_base() {
    //   return this.$calc.sum(this.purchase.purchase_order_products, "ipi_calc_base");
    // },

    // ipi_total() {
    //   return this.$calc.sum(this.purchase.purchase_order_products, "ipi");
    // },

    shipping_total() {
      return this.$calc.sum(this.purchase.purchase_order_products, "shipping");
    },

    // expenses_total() {
    //   return this.$calc.sum(this.purchase.purchase_order_products, "expenses");
    // },

    // insurance_total() {
    //   return this.$calc.sum(this.purchase.purchase_order_products, "insurance");
    // },

    product_total() {
      return this.$calc.sum(
        this.purchase.purchase_order_products,
        "product_total"
      );
    },

    purchase_total() {
      return this.$calc.sum(
        this.purchase.purchase_order_products,
        "purchase_total"
      );
    },
  },

  watch: {
    value(val) {
      this.purchase = val;
    },

    purchase: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  created() {
    this.purchase = this.value;
  },
};
</script>

<style>
</style>