<template>
  <v-navigation-drawer
    clipped
    temporary
    app
    right
    v-model="drawer"
    :permanent="drawer"
    width="800px"
  >
    <v-card-title primary-title class="d-flex justify-space-between">
      <span> {{ title }} Produto</span>

      <v-chip-group column v-model="isNewProduct">
        <v-chip filter :value="true"> Produto não cadastrado </v-chip>
      </v-chip-group>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <ProductSearch
            v-if="!isNewProduct"
            v-model="form.product"
            :showStock="true"
            :showGoogleSearch="true"
          />
          <app-text-field
            v-if="isNewProduct"
            v-model="form.product_name"
            label="Nome"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <app-number-field
            v-model="form.raw_quantity"
            label="Quantidade"
            @input="makeCalculations()"
          />
        </v-col>

        <!-- <v-col cols="4">
          <app-number-field
            v-model="form.quantity_multiple"
            label="Múltiplo Compra"
            @input="makeCalculations()"
          />
        </v-col>
        <v-col cols="4">
          <app-number-field
            :value="form.quantity"
            label="Quantidade"
            readonly
            :precision="4"
            @input="makeCalculations()"
          />
        </v-col>
      </v-row>

      <v-row> -->
        <v-col cols="4">
          <app-number-field
            v-model="form.price"
            label="Preço de Compra"
            :precision="2"
            @input="makeCalculations()"
          />
        </v-col>
        <v-col cols="4">
          <app-number-field
            v-model="form.product_total"
            label="Total"
            readonly
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-space-between">
          <div>
            <h3>Sub Total</h3>
          </div>
          <div>
            <h3>{{ $format.decimal(form.net_total) }}</h3>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <app-number-field
            v-model="form.shipping"
            label="Frete (R$)"
            @input="makeCalculations()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-space-between">
          <div>
            <h3>Total</h3>
          </div>
          <div>
            <h3>{{ $format.decimal(form.purchase_total) }}</h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <template v-slot:append>
      <div
        class="pa-4 text-md-left text-right"
        style="background-color: #f5f5f5"
      >
        <v-btn
          class="text-capitalize mr-2"
          color="primary"
          :disabled="!hasProduct"
          @click="handleAddProduct()"
        >
          {{ title }}
        </v-btn>
        <v-btn @click="drawer = false" text class="text-capitalize">
          Cancelar
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import ProductSearch from "@/components/product/ui/ProductSearch.vue";
export default {
  components: { ProductSearch },

  data() {
    return {
      isNewProduct: false,
      drawer: false,
      index: null,
      form: {},
      baseForm: {
        product: {},

        product_name: null,
        raw_quantity: 1,
        quantity_multiple: 1,
        quantity: 1,
        raw_price: 0,
        price: 0,
        product_total: 0,
        discount: 0,
        net_total: 0,
        shipping: 0,
        purchase_total: 0,
      },
    };
  },

  created() {
    this.resetForm();
  },

  computed: {
    quantity() {
      return this.form.quantity_multiple * this.form.raw_quantity;
    },

    netTotal() {
      return this.$calc.round(this.form.product_total - this.form.discount);
    },

    isInsert() {
      return this.index == null;
    },

    title() {
      return this.isInsert ? "Adicionar" : "Atualizar";
    },

    hasProduct() {
      return (
        this.form.product_name != null ||
        (this.form.product.id != null && this.form.product != null)
      );
    },
  },

  watch: {
    isNewProduct: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.isNewProduct) {
          this.form.product = {};
        }
      },
    },
  },

  methods: {
    open(data) {
      this.reset();
      this.drawer = true;
      if (data) {
        this.setProduct(data);
      }
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.resetForm();
    },

    setProduct(data) {
      this.index = data.index;
      this.form = JSON.parse(JSON.stringify(data.product));
      this.hasNewProduct();
    },

    hasNewProduct() {
      this.isNewProduct = this.form.product == null ? true : false;
    },

    handleAddProduct() {
      if (this.isInsert) {
        this.addProduct();
      } else {
        this.updateProduct();
      }

      this.resetForm();
    },

    addProduct() {
      this.$emit("store", this.form);
    },

    updateProduct() {
      this.$emit("update", {
        index: this.index,
        product: this.form,
      });
      this.drawer = false;
    },

    resetForm() {
      this.index = null;
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    makeCalculations() {
      this.setQuantity();
      this.setRawPrice();
      this.setProductTotal();
      this.setNetTotal();
      this.setPurchaseProductTotal();
    },

    setQuantity() {
      this.form.quantity = this.form.raw_quantity * this.form.quantity_multiple;
    },

    setRawPrice() {
      this.form.raw_price = this.$calc.round(
        this.form.price * this.form.quantity_multiple,
        4
      );
    },

    setProductTotal() {
      this.form.product_total = this.$calc.round(
        this.form.price * this.quantity
      );
    },

    setNetTotal() {
      this.form.net_total = this.$calc.round(
        this.form.product_total - this.form.discount
      );
    },

    setPurchaseProductTotal() {
      this.form.purchase_total = this.form.net_total + this.form.shipping;
    },
  },
};
</script>

<style></style>
