<template>
  <div>
    <h3 class="my-6">Informações da Nota</h3>
    <v-row class="d-flex justify-space-between">
      <v-col cols="12" md="5">
        <v-row>
          <v-col cols="12">
            <CompanySelect
              v-model="form.company_id"
              ref="CompanySelect"
              :disabled="!isDraft"
              :item-value="(val) => val.id"
            />
          </v-col>
          <v-col md="12">
            <SupplierSearch
              v-model="form.supplier"
              ref="SupplierSearch"
              :disabled="!isDraft"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="5">
        <v-row>
          <v-col cols="6">
            <app-text-field
              v-model="form.invoice_number"
              label="Numero da Nota"
              :disabled="!isDraft"
            />
          </v-col>

          <v-col md="6">
            <app-text-field
              v-model="form.scheduled_delivery_at"
              label="Previsão de Entrega"
              type="date"
              :disabled="!isDraft"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch.vue";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
export default {
  components: {
    SupplierSearch,
    CompanySelect,
  },
  props: {
    value: {},
    isDraft: {
      default: false,
    },
  },

  data() {
    return {
      form: {},
    };
  },

  watch: {
    value(val) {
      this.form = val;
    },

    form: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  created() {
    this.form = this.value;
  },
};
</script>

<style>
</style>