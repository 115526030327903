<template>
  <div>
    <v-row>
      <v-col>
        <v-divider />
        <PurchaseOrderDataSection
          v-model="purchase_order"
          :isDraft="disabledForm"
        />
      </v-col>
    </v-row>
    <div class="my-8" />
    <v-row>
      <v-col>
        <PurchaseOrderProductList
          :isDraft="disabledForm"
          :purchaseProducts="purchase_order.purchase_order_products"
          @deleteProduct="deleteProduct($event)"
          @store="handleStoreProduct($event)"
          @addProduct="handlePurchaseProductForm()"
          @editProduct="
            handlePurchaseProductForm({
              product: purchase_order.purchase_order_products[$event],
              index: $event,
            })
          "
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="order-md-0 order-1" cols="12" md="6">
        <v-textarea
          v-model="purchase_order.notes"
          label="Observações"
          :readonly="!disabledForm"
          outlined
          background-color="grey lighten-3"
          rows="3"
        />
      </v-col>

      <v-col class="order-md-1 order-0">
        <PurchaseOrderSummary v-model="purchase_order" />
      </v-col>
    </v-row>

    <v-row class="d-flex align-center order-md-0 order-1">
      <v-col>
        <CommentList v-model="purchase_order.comments" />
      </v-col>
      <v-col cols="12" md="6" v-if="!isInsert">
        <v-expansion-panels id="activities" flat outlined>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h4>Histórico de Edições</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item
                    v-for="(activity, index) in purchase_order.activities"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ activity.causer.short_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ activitiesStatus[activity.description] }}
                        em
                        {{ $format.dateTimeBr(activity.created_at) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row class="text-right">
      <v-col cols="12" md="12">
        <v-btn @click="back()" class="mr-2" text color="secondary">
          Voltar
        </v-btn>
        <v-btn
          v-if="disabledForm"
          @click="handleSave('draft')"
          class="mr-2"
          color="primary"
          outlined
        >
          Salvar Rascunho
        </v-btn>
        <v-btn
          v-if="disabledForm"
          @click="handleSave('confirmed')"
          class="mr-2"
          color="primary"
        >
          {{ isInsert ? "Salvar" : "Confirmar" }}
        </v-btn>
      </v-col>
    </v-row>

    <PurchaseOrderProductForm
      ref="PurchaseOrderProductForm"
      @store="addProduct($event)"
      @update="updateProduct($event)"
    />
  </div>
</template>

<script>
import PurchaseOrderProductForm from "@/components/purchase-order/form/PurchaseOrderProductForm";
import PurchaseOrderProductList from "@/components/purchase-order/list/PurchaseOrderProductList";
import PurchaseOrderSummary from "@/components/purchase-order/ui/PurchaseOrderSummary";
import PurchaseOrderDataSection from "@/components/purchase-order/section/PurchaseOrderDataSection.vue";
import CommentList from "@/components/comments/CommentList.vue";

export default {
  components: {
    PurchaseOrderProductForm,
    PurchaseOrderProductList,
    PurchaseOrderSummary,
    PurchaseOrderDataSection,
    CommentList,
  },

  data() {
    return {
      purchase_order: {
        purchase_order_products: [],
        status: null,
        comments: [],
      },

      editProductIndex: null,

      activitiesStatus: {
        created: "Criado",
        updated: "Atualizado",
        deleted: "Deletado",
        restored: "Restaurado",
      },
    };
  },

  computed: {
    isInsert() {
      return !this.$route.params.id;
    },
    hasProducts() {
      return this.purchase_order.purchase_order_products.length > 0;
    },
    isDraft() {
      return this.purchase_order.status === "draft";
    },
    isInRoute() {
      return this.purchase_order.status === "in_route";
    },
    isDelivered() {
      return this.purchase_order.status === "delivered";
    },
    isCanceled() {
      return this.purchase_order.status === "canceled";
    },

    disabledForm() {
      return !this.isDelivered || this.isCanceled;
    },
  },

  created() {
    this.showPurchase();
  },

  methods: {
    handleImportXml(data) {
      this.purchase_order = data;
    },

    handlePurchaseProductForm(data) {
      this.$refs.PurchaseOrderProductForm.open(data);
    },

    addProduct(product) {
      this.purchase_order.purchase_order_products.push(product);
    },

    updateProduct(data) {
      this.$set(
        this.purchase_order.purchase_order_products,
        data.index,
        data.product
      );
    },

    deleteProduct(productIndex) {
      this.$delete(this.purchase_order.purchase_order_products, productIndex);
    },

    handleStoreProduct(index) {
      this.editProductIndex = index;

      let purchaseProduct = JSON.parse(
        JSON.stringify(this.purchase_order.purchase_order_products[index])
      );
      purchaseProduct.supplier = this.purchase_order.supplier;

      this.$refs.StoreProductForm.open(null, purchaseProduct);
    },

    productStored(product) {
      this.purchase_order.purchase_order_products[
        this.editProductIndex
      ].product = product;

      this.editProductIndex = null;
    },

    showPurchase() {
      if (!this.isInsert) {
        this.$loading.start();

        this.$http
          .show("purchase-order/purchase-order", this.$route.params.id)
          .then((response) => {
            this.purchase_order = response.purchase_order;
            this.$loading.finish();
          })
          .catch((error) => {
            this.$loading.finish();
          });
      }
    },

    handleSave(status) {
      this.$loading.start();

      if (this.isInsert) {
        this.store(status);
      } else {
        this.update(status);
      }
    },

    store(status) {
      this.purchase_order.status = status;
      this.$http
        .store("purchase-order/purchase-order", this.purchase_order)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    update(status) {
      this.purchase_order.status = status;

      this.$http
        .update(
          "purchase-order/purchase-order",
          this.purchase_order.id,
          this.purchase_order
        )
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    back() {
      if (!this.$app.isMobile) {
        window.close();
      } else {
        this.$router.go(-1);
      }
    },
    processSaved() {
      this.$loading.finish();

      const purchaseIndex = window.opener;

      purchaseIndex.location.reload();

      if (!this.$app.isMobile) {
        window.close();
      } else {
        this.$router.go(-1);
      }
      purchaseIndex.location.reload();
    },
  },
};
</script>

<style lang="scss" >
#activities {
  .v-expansion-panel-header {
    background-color: #eeeeee;
  }
  .v-expansion-panel-content__wrap {
    background-color: #eeeeee;
  }
  .v-sheet {
    padding: 0;
  }
  .v-list-item-group {
    background-color: #eeeeee;
  }

  .v-list-item {
    background-color: #eeeeee;
  }
}
</style>
 