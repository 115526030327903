<template>
  <div>
    <template v-if="hasProducts">
      <v-data-table
        dense
        hide-default-footer
        disable-sort
        :headers="headers"
        :items="purchaseProducts"
        :item-class="checkIfNewProduct"
        :items-per-page="-1"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div class="my-2">
            <template v-if="item.product != null && item.product.id != null">
              {{ item.product.code }} -
              {{ item.product.name }}
            </template>
            <template v-else>
              {{ item.product_name }}
            </template>
          </div>
        </template>

        <template v-slot:[`item.quantity`]="{ item }">
          {{ $format.decimal(item.quantity_multiple * item.raw_quantity) }}
        </template>

        <template v-slot:[`item.purchase_price`]="{ item }">
          {{ $format.decimal(item.price) }}
        </template>
        <template v-slot:[`item.discount`]="{ item }">
          {{ $format.decimal(item.discount) }}
        </template>
        <template v-slot:[`item.shipping`]="{ item }">
          {{ $format.decimal(item.shipping) }}
        </template>

        <template v-slot:[`item.product_total`]="{ item }">
          {{ $format.decimal(item.product_total) }}
        </template>
        <template v-slot:[`item.purchase_total`]="{ item }">
          {{ $format.decimal(item.purchase_total) }}
        </template>
        <template v-slot:[`item.action`]="{ index }">
          <div v-if="isDraft">
            <v-btn
              @click.stop="$emit('editProduct', index)"
              text
              small
              icon
              color="primary"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              @click.stop="$emit('deleteProduct', index)"
              text
              small
              icon
              color="primary"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </template>
    <template v-if="isDraft">
      <v-btn @click="$emit('addProduct')" class="my-5" color="primary">
        <v-icon>mdi-plus </v-icon> Adicionar Produto
      </v-btn>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    purchaseProducts: {
      default() {
        return [];
      },
    },
    isDraft: {
      default() {
        return false;
      },
    },
  },

  data() {
    return {
      mobileHeaders: [
        { text: "Nome", value: "name", align: "start", width: "45%" },
        { text: "", value: "action", align: "right", width: "10%" },
      ],
      headers: [
        { text: "Nome", value: "name", align: "start", width: "45%" },
        { text: "Quantidade", value: "quantity", align: "center" },
        { text: "Preço", value: "purchase_price", align: "center" },
        // { text: "Desconto", value: "discount", align: "center" },
        { text: "Frete (R$)", value: "shipping", align: "center" },
        { text: "Sub Total", value: "product_total", align: "center" },
        { text: "Total", value: "purchase_total", align: "center" },
        { text: "", value: "action", align: "right", width: "10%" },
      ],
    };
  },

  computed: {
    hasProducts() {
      return this.purchaseProducts.length > 0;
    },
  },
  methods: {
    checkIfNewProduct(item) {
      // if (!item.product.id) {
      //   return "new-product";
      // }
      return null;
    },
  },
};
</script>

<style>
.new-product {
  background-color: #eeeeee;
}
</style>